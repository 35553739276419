<template>
    <!-- 组队学习列表 -->
    <div class="study-wrap">
        <div class="study-tip" v-if="studyData.length != 0">
            <img class="tip-img" src="~assets/images/task/sing-tip.png" alt="">
            <div class="tip-text">每{{ configsvalue.group_unit }}最多报{{ configsvalue.group_frequency }}个学习小组</div>
        </div>
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="studyData.length > 10 ? '没有更多了': ''"
          :immediate-check="false"
          @load="onLoad"
      >
        <div class="study-box">
          <study :study=" studyData "></study>
        </div>
      </van-list>
    </div>
</template>

<script>
import Study from 'components/study'
import {reactive} from "vue"
export default {
  name: "sign",
  data(){
    return{
      studyData: [],
      loading: false,
      finished: false,
      page: 1,
      configsvalue: {}
    }
  },
    beforeRouteEnter(to, from) {
        window.scrollTo(0,0);
    },
    created() {
      this.getStudy()
      this.getConfigs()
  },
  methods:{
  //  获取学习小组列表
    getStudy(){
      let url = this.$api.Groups + '?page=' + this.page;
      this.page ++;
      this.finished = true;
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.loading = false;
          if(res.data.data.data.length){
            this.finished = false;
            this.studyData = this.studyData.concat(res.data.data.data)
          }else {
            this.finished = true;
          }
        }
      })
    },
  //  底部加载
    onLoad(){
      this.getStudy();
    },
    // 获取配置
    getConfigs(){
      let url = this.$api.StudyConfigs +'?key=config_group'
      this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.configsvalue = res.data.data.value
          //day 天 week 周 month 月 year 年
          switch (this.configsvalue.group_unit){
            case 'day':
              this.configsvalue.group_unit = '天'
              break;
            case 'week':
              this.configsvalue.group_unit = '周'
              break;
            case 'month':
              this.configsvalue.group_unit = '月'
              break;
            case 'year':
              this.configsvalue.group_unit = '年'
              break;
          }
          reactive(this.tasksData)
        }
      })

    }
  },
  components:{
    Study
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/study/index.scss";
</style>